.employee-overview {
  height: 600px;
  font-size: 19px;
}

.employee-overview.not-manager {
  height: 400px;
}

.employee-info {
  padding: 10px;
  padding-top: 40px;
  font-size:19px;
  text-align: center;
}
.employee-info .employee-technology {
  font-weight: 400;
  padding-left: 2px;
}
.employee-notes  .p-paginator {
  background: transparent;
  border-color: transparent;
  color: #276749;
}
.employee-notes .p-paginator .p-paginator-pages .p-paginator-page {
  background-color: unset;
  color: black;
}
.employee-notes .p-paginator .p-paginator-first,
.employee-notes.p-paginator .p-paginator-prev,
.employee-notes.p-paginator .p-paginator-next,
.employee-notes .p-paginator .p-paginator-last,
.employee-notes  .p-paginator-pages {
  background-color: #C6F6D5;
}
.employee-notes  .p-highlight {
  background-color: #276749;
  color: black;
}
.employee-notes  .p-paginator-page-end {
  background-color: transparent;
  color: black;
}
.employee-notes  .p-paginator-element {
  background-color: transparent;
  color: black;
}
.employee-notes .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.employee-notes .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.employee-notes .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.employee-notes .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: hsl(139, 31%, 74%);
  border-color: transparent;
  color: black;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: rgba(255, 255, 255, 0.6);
  min-width: 2.286em;
  height: 2.286em;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: rgba(255, 255, 255, 0.6);
  min-width: 2.286em;
  height: 2.286em;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}
.employee-notes .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgb(254, 255, 233);
  border-color:rgb(254, 255, 233);
  color: black;
}
.employee-notes .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: transparent;
  color: black;
}
.employee-notes form {
  width:100%;
}
::-webkit-scrollbar {
  width:4px;
}
::-webkit-scrollbar-track {
  background: #EDF2F7;
  border-radius: 100vh;
  margin-block: 0.5px;
}
::-webkit-scrollbar-thumb {
  background-color:#61b07a;
  border-radius: 100vh;
  border-color: 0.1px solid #EDF2F7;
}
::-webkit-scrollbar-thumb:hover {
  background-color: hsl(139, 41%, 63%)
}
::-webkit-scrollbar-thumb:active {
  background-color: hsl(139, 41%, 63%)
}
@supports(scrollbar-color: red blue){
  * {
    scrollbar-color:  #61b07a #EDF2F7;
    scrollbar-width: auto;
  }
}

@media screen and (min-width: 768px) {
  .employee-overview {
    height: 560px;
    font-size: 22px;
  }
  .employee-info{
    padding-top:90px;
    font-size: 22px;
  
  }
  .employee-info img {
    height: 85px;
  }
  .employee-projects {
    padding-top:30px;
  }
  
}