@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,700;1,400&display=swap');

/* ***** RESETS ***** */
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  /*  1 rem = 10px > 10:16 = 0.625 > 62.5%  */
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: 'Merriweather Sans', sans-serif;
}

a,
a:visited,
a:active,
a:hover {
  text-decoration: none;
}
