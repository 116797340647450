:root {
  --background: #141d2f;
  --background-light: #1e2a47;
  --input-focus: #4b556c;
  --hover-text: #9399a7;
  --text-blue: #141d2f;
  --shadow-bg-light: 0 16px 30px -10px rgba(70, 96, 187, 0.198567);
  --white-text: #fff;
  --button-green: #0e7c2b;
  --button-green-hover: #11b13b;
  --error: #ff1744;
}
