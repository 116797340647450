option {
    color: #2f3c4b;
}


option:focus,
option:active,
option:checked {
    color: black;
    /* background: #2f3c4b; */
    font-weight: 600;
}

option:hover {
    color: black;
    background: red;
    font-weight: 600;
}

/* .select-css option:nth-child(odd) {
    background-color: rgb(153, 15, 63);
    color: blue;
} */

/* .select-css {
    background-color: red;
    color: blue;
} */

/* ispravi sve select-css */