@use './utility-styles/' as *;

.section-main {
  min-height: 100vh;
  background-color: #25323d;
  color: var(--white-text);
  padding-top: 100px;
  padding-bottom: 100px;
}

// .section-main--logged {
//   padding-left: 300px;
//   padding-top: 100px;
// }
.section-main--logged-bgGradient {
  background: #25323d;
}

@media screen and (min-width: 768px) {
  .section-main--logged {
    // padding: 70px 0px 0 10px;
    padding-left: 300px;
   padding-top: 100px;
  }
}
