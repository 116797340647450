@use '../../../utility-styles' as *;

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20rem;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    background-color: #2f3c4b;
    box-shadow: var(--shadow-bg-light);
    padding: 3.2rem;
    border-radius: 1.5rem;
    max-width: 90%;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 700;
  }
}

// Inputs:
.login {
  &__inputs-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__form-control {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__input-label {
    font-size: 1.4rem;
    margin-bottom: 0.4rem;
  }

  &__input {
    width: 37.2rem;
    padding: 1.2rem 2.4rem;
    border: none;
    border-radius: 1rem;
    font-family: inherit;
    font-size: 1.4rem;
    color: var(--text-blue);
    max-width: 100%;

    &:focus {
      outline: none;
      background-color: var(--input-focus);
      color: var(--white-text);
    }
  }

  &__error-text {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--error);
  }
}

.invalid input {
  border: 0.1rem solid var(--error);
  background-color: #d26e6e;
}

// Button box:
.login {
  &__btn-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.6rem;
  }

  &__paragraph {
    font-size: 1.4rem;
    color: var(--white-text);
    cursor: pointer;
    transition: all 300ms ease-out;

    &:hover {
      color: var(--hover-text);
    }
  }

  &__btn {
    @extend %btn;
    padding: 1.2rem 2.4rem;
    background-color: var(--button-green);
    font-size: 1.4rem;
    color: var(--white-text);
    border-radius: 1rem;

    &:hover {
      background-color: var(--button-green-hover);
    }

    &:disabled {
      background-color: var(--error);
      cursor: not-allowed;
    }
  }
}
