// ==== BUTTONS:
%btn {
  display: inline-block;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
  transition: all 300ms ease-out;
}

*:focus {
  box-shadow: none !important;
}
